export const planificacioquerys = [
    {
        "id": 13,
        "type": "check","question": "Quin és el nivell de planificació de l'empresa respecte la seva internacionalització?",
        responses:[
            {
                "title": "No disposem de pla d'internacionalització",
                "points": 0
            },
            {
                "title": "Existeix un pla generals amb objectius aproximats",
                "points": 1
            },
            {
                "title": "Es determinen els productes i mercats objectiu, es fixen uns nivells de vendes i beneficis en un període determinat",
                "points": 2
            }
        ]
    },
    {
        "id": 14,
        "type": "check","question": "Motivació personal i expectatives adeqüades",
        responses:[
            {
                "title": "L'equip directiu no considera que hi hagi una necessitat d'exportar.",
                "points": 0
            },
            {
                "title": "L'equip directiu considera la necessitat d'exportar però no té clares les expectatives i els riscs del comerç exterior.",
                "points": 1
            },
            {
                "title": "L'equip directiu considera la necessitat d'exportar i té clares les expectativies i els riscs del comerç exterior.",
                "points": 2
            }
        ]
    },
    {
        "id": 15,
        "type": "check","question": "Capacitat d'implicació de l'equip directiu",
        responses:[
            {
                "title": "L'equip directiu no podrà dedicar el temps necessari per gestionar la internacionalització",
                "points": 0
            },
            {
                "title": "L'equip directiu no podrà dedicar el temps necessari per gestionar la internacionalització però assignaran un responsable amb capacitat de decisió.",
                "points": 1
            },
            {
                "title": "L'equip directiu podrà dedicar el temps necessari per gestionar la internacionalització.",
                "points": 2
            }
        ]
    },
    {
        "id": 16,
        "type": "check","question": "Recursos econòmics disponibles.",
        responses:[
            {
                "title": "Actualment no disposem de recursos per afrontar el procés d'internacionalització",
                "points": 0
            },
            {
                "title": "Podriem realitzar petites inversions i/o fer almenys 4 accions promocionals a l'any (2.000€ per acció)",
                "points": 1
            },
            {
                "title": "L'empresa està ben capitalitzada i/o té possibilitats de recursos addicionals",
                "points": 2
            }
        ]
    },
    {
        "id": 17,
        "type": "check","question": "Recursos humans i capacitats",
        responses:[
            {
                "title": "A l'empresa no hi ha recursos humans amb coneixements de comerç exterior i idiomes.",
                "points": 0
            },
            {
                "title": "A l'empresa no hi ha recursos humans amb experiència en comerç exterior però si amb idiomes (com a mínim nivell molt alt d'anglès).",
                "points": 1
            },
            {
                "title": "A l'empresa hi ha recursos humans amb experiència en gestió de comerç exterior i amb domini d'idiomes.",
                "points": 2
            }
        ]
    },
    {
        "id": 18,
        "type": "check","question": "Material promocional i eines de comunicació",
        responses:[
            {
                "title": "L'empresa no disposa de pàgina web ni presentacions de l'empresa en cap altre suport",
                "points": 0
            },
            {
                "title": "L'empresa disposa de pàgina web o presentacions de l'empresa en altres suports només en català o castellà",
                "points": 1
            },
            {
                "title": "L'empresa disposa de pàgina web o presentacions de l'empresa en altres suports en idiomes estrangers",
                "points": 2
            }
        ]
    }
]
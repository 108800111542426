export const caracteristicasproducto = [
    {
        "id": 19,
        "type": "check","question": "Pel que fa a les característiques del producte o servei…",
        "responses": [
            {
                "title": "És similar al de la competència",
                "points": 0
            },
            {
                "title": "Té avantatges respecte la competència",
                "points": 1
            },
            {
                "title": "No té competència",
                "points": 2
            }
        ]
    },
    {
        "id": 20,
        "type": "check","question": "Quina és la posició del producte en el mercat interior?",
        "responses": [
            {
                "title": "Té poca presència",
                "points": 0
            },
            {
                "title": "Té una posició intermitja",
                "points": 1
            },
            {
                "title": "Està entre els líders del mercat",
                "points": 2
            }
        ]
    },
    {
        "id": 21,
        "type": "check","question": "A qui va adreçat el producte?",
        "responses": [
            {
                "title": "A un segement reduït de mercat",
                "points": 0
            },
            {
                "title": "A un mercat determinat",
                "points": 1
            },
            {
                "title": "A diversos mercats",
                "points": 2
            }
        ]
    },
    {
        "id": 22,
        "type": "check","question": "Pel que fa al servei postvenda…",
        "responses": [
            {
                "title": "És fonamental",
                "points": 0
            },
            {
                "title": "És ocasional",
                "points": 1
            },
            {
                "title": "No és necessari",
                "points": 2
            }
        ]
    },
    {
        "id": 23,
        "type": "check","question": "Pel que fa al transport i adaptació del producte o servei al mercat internacional…",
        "responses": [
            {
                "title": "És difícil d'enviar i s'han de fer modificacions importants per comercialitzar-lo en mercats internacionals (o en el cas de serveis, es presta de manera molt diferent)",
                "points": 0
            },
            {
                "title": "Realitzant unes petites adaptacions pot ser venut/prestat en el país de destí",
                "points": 1
            },
            {
                "title": "No és necessari adaptar-lo ja que es consumeix / presta de la mateixa manera",
                "points": 2
            }
        ]
    },
    {
        "id": 24,
        "type": "check","question": "Pel que fa a l'emmagatzematge…",
        "responses": [
            {
                "title": "És necessari disposar d'espai per una quantitat important d'existències o requereix d'unes condicions especials d'emmagatzematge",
                "points": 0
            },
            {
                "title": "Cal disposar d'una certa quantitat d'existències o requereix un espai important d'emmagatzematge",
                "points": 1
            },
            {
                "title": "No és necessari disposar de grans quantitats d'existències o bé és senzill d'emmagatzemar",
                "points": 2
            }
        ]
    },
    {
        "id": 25,
        "type": "check","question": "Quins considera que són els avantatges competitius de la seva empresa?",
        "responses": [
            {
                "title": "Una barreja poc clara entre flexibilitat, servei, qualitat i preu, però sobretot els dos primers.",
                "points": 0
            },
            {
                "title": "Un producte de qualitat i bon preu, tot i que no molt diferenciat.",
                "points": 1
            },
            {
                "title": "Un producte diferenciat, fins a cert punt únic, i amb molt bon nivell de qualitat",
                "points": 2
            }
        ]
    },
    {
        "id": 26,
        "type": "check","question": "Atractiu del producte / servei",
        "responses": [
            {
                "title": "És molt local, fet pel qual no és conegut en altres països.",
                "points": 0
            },
            {
                "title": "És conegut en altres països i sol existir producció local.",
                "points": 1
            },
            {
                "title": "És conegut en altres països però el nostre és diferent al de la resta de competidors.",
                "points": 2
            }
        ]
    },
    {
        "id": 27,
        "type": "check","question": "Com afronta la seva empresa la qüestió de la qualitat?",
        "responses": [
            {
                "title": "Ens preocupem permanentment per la qualitat i el disseny, per tal que estiguin en línia amb les necessitats dels nostres clients.",
                "points": 0
            },
            {
                "title": "Estem implantant un sistema de qualitat en el procés de fabricació.",
                "points": 1
            },
            {
                "title": "Disposem de sistemes de qualitat normalitzats (tipus ISO).",
                "points": 2
            }
        ]
    },
    {
        "id": 28,
        "type": "check","question": "Anàlisi del preu del producte / servei",
        "responses": [
            {
                "title": "El preu del producte / servei és comparativament elevat respecte el de la competència.",
                "points": 0
            },
            {
                "title": "La decisió de compra no suposa una decisió de risc pel comprador.",
                "points": 1
            },
            {
                "title": "El producte compta amb una millor capacitat competitiva que la resta dels que hi ha al mercat.",
                "points": 2
            }
        ]
    }
]
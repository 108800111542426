export const analisisquestions = [
    {
        "id": 6,
        "type": "check", "question": "Grau de maduresa de l'empresa",
        "responses": [
            {
                "title": "L'empresa és de nova creació (menys de 3 anys)",
                "points": 0
            },
            {
                "title": "L'empresa està consolidada tot i que no disposa d'una distribució comercial madura.",
                "points": 1
            },
            {
                "title": "L'empresa està consolidada i disposa d'una distribució comercial madura (agents, representants, comercials, etc.).",
                "points": 2
            }
        ]
    },
    {
        "id": 7,
        "type": "check", "question": "Presència en mercats internacionals",
        "responses": [
            {
                "title": "L'empresa no ha venut mai a l'exterior",
                "points": 0
            },
            {
                "title": "L'empresa ha fet vendes reactives a l'exterior",
                "points": 1
            },
            {
                "title": "L'empresa ven de forma regular a l'exterior menys del 15% de la facturació total",
                "points": 2
            },
            {
                "title": "L'empresa ven de forma regular a l'exterior més del 15% de la facturació total",
                "points": 3
            }
        ]
    },
    {
        "id": 8,
        "type": "check", "question": "Grau d'internacionalització del sector",
        "responses": [
            {
                "title": "La majoria de competidors espanyols estàn exportant",
                "points": 0
            },
            {
                "title": "Alguns competidors espanyols estan exportant, i a la vegada competidors estrangers entren al mercat espanyol",
                "points": 1
            },
            {
                "title": "En general, els competidors espanyols no estan exportant.",
                "points": 2
            }
        ]
    },
    {
        "id": 9,
        "type": "check", "question": "Com és la situació financera de la seva empresa?",
        "responses": [
            {
                "title": "Més que ajustada",
                "points": 0
            },
            {
                "title": "Normal. No ens sobren els recursos però tampoc tenim problemes",
                "points": 1
            },
            {
                "title": "Còmoda.",
                "points": 2
            }
        ]
    },
    {
        "id": 10,
        "type": "check", "question": "Quina és l'evolució de la facturació de l'empresa dels darrers tres anys?",
        "responses": [
            {
                "title": "Decreixent",
                "points": 0
            },
            {
                "title": "Estable",
                "points": 1
            },
            {
                "title": "Creixent",
                "points": 2
            }
        ]
    },
    {
        "id": 11,
        "type": "check", "question": "Quina és l'evolució dels resultats de l'empresa dels darrers tres anys?",
        "responses": [
            {
                "title": "Han empitjorat",
                "points": 0
            },
            {
                "title": "S'han mantingut",
                "points": 1
            },
            {
                "title": "Han millorat",
                "points": 2
            }
        ]
    },
    {
        "id": 12,
        "type": "check", "question": "En quin % de la capacitat de producció màxima es troba l'empresa actualment?",
        "responses": [
            {
                "title": "Més del 75%",
                "points": 0
            },
            {
                "title": "Entre el 50% i el 75%",
                "points": 1
            },
            {
                "title": "Entre el 25% i el 50%",
                "points": 2
            },
            {
                "title": "Menys del 25%",
                "points": 3
            }
        ]
    }
]
export const nousclients = [
    {
        "id": 29,
        "type": "check","question": "Disposa l'empresa d'una estratègia comercial?",
        "responses": [
            {
                "title": "No, els objectius i accions comercials es van definit sobre la marxa",
                "points": 0
            },
            {
                "title": "Si, disposem d'una estratègia comercial amb objectius i accions clarament definides que s'evaluen periòdicament.",
                "points": 1
            },
            {
                "title": "Si, disposem d'un pla estratègic que inclou un pla comercial amb objectius i accions concretes.",
                "points": 2
            }
        ]
    },
    {
        "id": 30,
        "type": "check","question": "Com és la força comercial de l'empresa?",
        "responses": [
            {
                "title": "No existeix força comercial",
                "points": 0
            },
            {
                "title": "La comercialització es fa a través de comercials externs",
                "points": 1
            },
            {
                "title": "Disposem d'una xarxa comercial pròpia formada per comercials a nòmina de l'empresa",
                "points": 2
            }
        ]
    },
    {
        "id": 31,
        "type": "check","question": "Com identifica la seva empresa les necessitats dels clients?",
        "responses": [
            {
                "title": "De manera informal (a través de eles relacions habituals amb clients i proveïdors)",
                "points": 0
            },
            {
                "title": "A través de la nostra xarxa comercial (amb questionaris i reunions periòdiques amb clients)",
                "points": 1
            },
            {
                "title": "Amb estudis de mercat (amb informació de diverses fonts)",
                "points": 2
            }
        ]
    },
    {
        "id": 32,
        "type": "check","question": "Com garanteix la seva empresa l'atenció als clients?",
        "responses": [
            {
                "title": "A través de les relacions normals i properes que solem mantenir amb els clients",
                "points": 0
            },
            {
                "title": "A través d'un sistema d'atenció personalitzada",
                "points": 1
            },
            {
                "title": "Amb un servei d'atenció al client que evalua regularment la seva qualitat i satisfacció dels clients.",
                "points": 2
            }
        ]
    },
    {
        "id": 33,
        "type": "check","question": "Quins esforços de promoció i publicitat fa la seva empresa?",
        "responses": [
            {
                "title": "Els més típics (catàlegs, fires i merchandising)",
                "points": 0
            },
            {
                "title": "A banda dels més típics, activitats d'informació i/o promoció amb distribuïdors, promocions en punts de venda, anuncis, pàgina web i màrqueting digital",
                "points": 1
            },
            {
                "title": "Tenim un pla anual de promoció i publicitat que forma part del nostre pla de màrqueting.",
                "points": 2
            }
        ]
    },
]
export const dadesempresa = [
    {
        "id": 1,
        "type": "select",
        "question": "Sector d'activitat",
        "responses": [{"title": "Bens de consum"},{"title": "Producció agrícola i ramadera"}, {"title": "Construcció"}, {"title": "Esport i oci"}, {"title": "Energia i medi ambient"},{"title":"Logística i transport"},{"title":"Metal·lurgia i electrònica"},{"title":"Productes químics i matèries primes"},{"title":"Salut i Indústria farmacèutica"}]
    },
    {
        "id": 2,
        "type": "select",
        "question": "Subsector d'activitat",
        "responses": [
            {key:"Bens de consum",value:[{"title":"Alimentació i nutrició"},
                    {"title":"Begudes alcohòliques"},
                    { "title":"Begudes sense alcohol"},
                    { "title":"Cosmética i higiene personal"},
                    { "title":"Equpament de la llar"},
                    { "title":"Joguines"},
                    { "title":"Mascotes"},
                    { "title":"Productes de neteja"},
                    { "title":"Moda i complements"},
                    { "title":"Altres"}]},
            {key: "Producció agrícola i ramadera",value:[{"title":"Agricultura"},{"title":"Ramaderia"},{"title":" Pesca i aquicultura"},{"title":"Silvicultura"}]},
            {key:"Construcció",value:[{ "title":"Arquitectura"},{ "title":"Enginyeria civil"}]},
            {key:"Esport i oci",value:[{"title":"Aficions"},{"title":"Art i cultura"},{"title":"Esport i fitness"},{"title":"Altres"}]},
            {key:"Energia i medi ambient",value:[{"title":"Aigua i aigües residuals"},{"title":"Emissions"},{"title":"Energia"},{"title":"Gestió de residus"}]},
            {key:"Logística i transport",value:[{"title":"Aviació"},{"title": "Logística"},{"title":"Trafico rodat"},{"title":"Transport ferroviari"},{"title":"Altres"}]},
            {key:"Metal·lurgia i electrònica",value:[{"title":"Construcción naval"},{"title":"Electrònica"},{"title":"Industria aeroespacial"},{"title": "Industria de defensa"},{"title": "Ingenieria"},{"title": "Metalls"}, {"title":"Producció de vehicles"}, {"title":"Altres"}]},
            {key:"Productes químics i matèries primes",values:[{"title":"Combustibles fòssils"},{"title": "Industria química"},{"title": "Mineria, metalls i minerals"},{"title": "Paper i pasta de paper"},{"title":"Plàstic i cautxú"},{"title": "Productes minerals no metàl·lics"}]},
            {key:"Salut i Indústria farmacèutica",values:[{"title":"Assistència i cures"},{"title":"Industria farmacéutica"}]}
        ]
    },
    {
        "id": 3,
        "type": "check",
        "question": "Què comercialitza la vostra empresa?",
        "responses": [{"title": "productes", "points": 0}, {
            "title": "serveis",
            "points": 0
        }, {"title": "productes i serveis", "points": 0}]
    },
    {
        "id": 4,
        "type": "check",
        "question": "Quin és el nombre de treballadors de l’empresa (incloent-vos a vosaltres mateixos)?",
        "responses": [{"title": "1", "points": 0}, {"title": "Entre 2 i 9", "points": 0}, {
            "title": "Entre 10 i 49",
            "points": 0
        }, {"title": "Entre 50 i 249", "points": 0}]
    },
    {
        "id": 5,
        "type": "check",
        "question": "Quin és el volum de facturació de l'empresa?",
        "responses": [{"title": "Menys de 50000€", "points": 0}, {
            "title": "Entre 50000€ i 100000€",
            "points": 0
        }, {"title": "Entre 100000€ i 250000€", "points": 0}, {
            "title": "Entre 250000€ i 1000000€",
            "points": 0
        }, {"title": "Entre 1000000€ i 5000000", "points": 0}, {
            "title": "Entre 5000000€ i 10000000€",
            "points": 0
        }, {"title": "Entre 10000000€ i 50000000€", "points": 0}, {"title": "Més de 5000000€", "points": 0}]
    }
]